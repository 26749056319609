var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dialog" }, [
    _c("p", { staticClass: "login-txt" }, [_vm._v("登录")]),
    _c("p", { staticClass: "tip" }, [
      _vm._v(
        "活动2021年1月10日开始哦！每月10日前将为达标客户发送短信提醒用户名及密码，不见不散！"
      ),
    ]),
    _c("div", { staticClass: "cell" }, [
      _c("i", { staticClass: "icon icon-mobile" }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.seaName,
            expression: "seaName",
          },
        ],
        attrs: { type: "text", placeholder: "请输入客户号" },
        domProps: { value: _vm.seaName },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.seaName = $event.target.value
          },
        },
      }),
    ]),
    _c("div", { staticClass: "cell" }, [
      _c("i", { staticClass: "icon icon-password" }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.password,
            expression: "password",
          },
        ],
        attrs: { type: "password", placeholder: "请输入登录密码" },
        domProps: { value: _vm.password },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.password = $event.target.value
          },
        },
      }),
    ]),
    _c("div", { staticClass: "login-btn" }, [
      _c(
        "p",
        {
          staticClass: "submit",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.submit()
            },
          },
        },
        [_vm._v("登录")]
      ),
      _c(
        "p",
        {
          staticClass: "cencel",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.cancel.apply(null, arguments)
            },
          },
        },
        [_vm._v("取消")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }