<template>
  <div class="dialog">
    <p class="login-txt">登录</p>
    <p class="tip">活动2021年1月10日开始哦！每月10日前将为达标客户发送短信提醒用户名及密码，不见不散！</p>
    <div class="cell">
      <i class="icon icon-mobile"></i>
      <input type="text" v-model="seaName" placeholder="请输入客户号">
    </div>
    <div class="cell">
      <i class="icon icon-password"></i>
      <input type="password" v-model="password" placeholder="请输入登录密码">
    </div>
    <div class="login-btn">
      <p class="submit" @click.stop="submit()">登录</p>
      <p class="cencel" @click.stop="cancel">取消</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'loginDialog',
  data() {
    return {
      seaName: '',
      password: ''
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    submit() {
      this.$emit('submit', this.seaName, this.password)
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../../common/less/variable.less";
@import "../../../../common/less/mixin.less";

.dialog {
    .wh(6rem, 4.7rem);
    background: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    margin: -2.2rem 0 0 -3rem;
    padding: 0.2rem;
    border-radius: 0.08rem;

    .login-txt {
      color: #333;
      font-weight: bold;
    }

    .tip {
      margin-top: 0.2rem;
      color: #666;
      font-size: 0.24rem;
      line-height: 0.3rem;
    }

    .cell {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      padding-left: 0.52rem;
      padding-top: 0.4rem;
      padding-bottom: 0.3rem;
      border-bottom: 1px solid @color-row-line;

      .icon {
        position: absolute;
        top: 0.44rem;
        left: 0;
        display: inline-block;
        .wh(0.32rem, 0.32rem);
        z-index: 2;
      }

      input {
        .wh(100%, 0.4rem);
        .sc(@fontsize-medium, @color-dark-grey);
        line-height: 0.4rem;

        &::-webkit-input-placeholder {
          color: #b3b3b3;
          font-size: 0.24rem;
        }
      }

      .icon-mobile {
        width: 0.24rem;
        .bis("../../../../assets/icon_denglu_shouji");
      }

      .icon-password {
        width: 0.28rem;
        .bis("../../../../assets/icon_denglu_mima");
      }
    }

    .login-btn {
      width: 100%;
      padding: 0.4rem 0 0.2rem 0;
      font-size: 0.24rem;
      overflow: hidden;

      .cencel {
        .wh(1.2rem, 0.48rem);
        background-color: rgba(242, 242, 242, 1);
        float: right;
        text-align: center;
        line-height: 0.48rem;
        border-radius: 0.08rem;
        margin-right: 0.2rem;
      }

      .submit {
        background: #2E8FF4;
        color:#fff;
        .wh(1.2rem, 0.48rem);
        float: right;
        text-align: center;
        line-height: 0.48rem;
        border-radius: 0.08rem;
      }
    }
  }
</style>