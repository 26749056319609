var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "sea-index",
      style: {
        background:
          "url(" + _vm.bg + ") no-repeat scroll center top / 100% auto",
      },
    },
    [
      _vm.userInfo.business_id
        ? _c(
            "p",
            {
              staticClass: "login-status",
              on: {
                click: function ($event) {
                  return _vm.logout()
                },
              },
            },
            [_vm._v("退出登录")]
          )
        : _vm._e(),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          {
            staticClass: "entry",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.judgeLogin(1)
              },
            },
          },
          [
            _c("img", {
              attrs: { src: require("@/assets/haiyuansu/month.png"), alt: "" },
            }),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "entry",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.judgeLogin(2)
              },
            },
          },
          [
            _c("img", {
              attrs: { src: require("@/assets/haiyuansu/other.png"), alt: "" },
            }),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "entry",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.judgeLogin(3)
              },
            },
          },
          [
            _c("img", {
              attrs: { src: require("@/assets/haiyuansu/team.png"), alt: "" },
            }),
          ]
        ),
      ]),
      _vm.loginDialog
        ? _c(
            "div",
            {
              staticClass: "login-entrys",
              on: {
                click: function ($event) {
                  if ($event.target !== $event.currentTarget) return null
                  return _vm.appear.apply(null, arguments)
                },
              },
            },
            [
              _c("login-dialog", {
                on: {
                  submit: function ($event) {
                    return _vm.submit(arguments)
                  },
                  cancel: _vm.cancel,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("alert-tip", {
        attrs: {
          type: _vm.type,
          "show-alert": _vm.show_tip,
          "alert-text": _vm.tip,
        },
        on: { changeShowAlert: _vm.changeShow },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }