<template>
  <div class="sea-index" :style="{background: 'url(' + bg + ') no-repeat scroll center top / 100% auto'}">
    <p v-if="userInfo.business_id" class="login-status" @click="logout()">退出登录</p>
    <div class="content">
      <div class="entry" @click.stop="judgeLogin(1)">
        <img src="@/assets/haiyuansu/month.png" alt="">
      </div>
       <div class="entry" @click.stop="judgeLogin(2)">
        <img src="@/assets/haiyuansu/other.png" alt="">
      </div>
      <div class="entry" @click.stop="judgeLogin(3)">
        <img src="@/assets/haiyuansu/team.png" alt="">
      </div>
    </div>

    <div v-if="loginDialog" class="login-entrys" @click.self="appear">
      <login-dialog @submit="submit(arguments)" @cancel="cancel"></login-dialog>
    </div>

    <alert-tip :type="type" :show-alert="show_tip" :alert-text="tip" @changeShowAlert="changeShow"></alert-tip>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import {debounce} from "@/utils/debounce";
import md5 from "js-md5";
import {login4, getColor, logout} from "@/services";
import {toolTip, inputMixins} from "@/mixins";
import AlertTip from "@/components/common/AlertTip";
import LoginDialog from './loginDialog.vue'
import { getActivityDetail, getActivityStatus } from "@/services/my";

export default {
  name: 'seaIndex',
  components: {
    AlertTip,
    LoginDialog
  },
  data() {
    return {
      bg: require('@/assets/haiyuansu/sanchongli.png'),
      loginDialog: false,
      entrysStatus: 0,  //  入口表示
      seaType: 0,  //  抽奖类型
      loginTxt: ''
    }
  },
  mixins: [toolTip, inputMixins],
  created() {
    document.title = '三重礼'
    if (!this.userInfo.business_id) {
      this.loginTxt = '请登录'
    } else {
      this.loginTxt = '退出登录'
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo, //用户信息
    })
  },
  methods: {
    ...mapMutations(["getUser", "getSessionId", 'clear']),

    async getTurntableInfo(seaType) {
      try {
        let params = {
          type: seaType
        }
        const res = await getActivityDetail(params)
        if (res.code === 0) {
          this.$router.push({
            path: '/lucky_wheel',
            query: {
              type: seaType
            }
          })
        }
        if (res.code === -1 && res.msg !== '此用户没有参加该活动的权限') {
          this.$router.push({
            path: '/lucky_wheel',
            query: {
              type: seaType
            }
          })
        }

        if (res.msg === '此用户没有参加该活动的权限') {
          this.show_layer("cancel", "本期活动未达标，下期努力呀~");
        }
        
      } catch (e) {
        this.$dialog.toast({
          mes: '网络繁忙，稍后再试',
          icon: 'error',
          timeout: 1500
        });
      }
    },

    login() {
      this.loginDialog = true
    },
    
    //  判断活动是否开启
    async getActivityStatus(type) {
      try {
        let params = {
          type: type
        }
        const res = await getActivityStatus(params)
        console.log({res})
        if (res.code === -2222) {
          this.show_layer("cancel", "本期活动未达标，下期努力呀~");
        } 
        if (res.code === -1111 || res.code === 0) {
          this.$router.push({
            path: type == 3 ? '/ceremony' : '/lucky_wheel',
            query: {
              type: type
            }
          })
        }
      } catch (e) {}
    },

    //退出登录
    async logout(){
      try {
        const res = await logout();
        if (res.code === 0) {
          this.loginTxt = '请登录'
          sessionStorage.removeItem('show')
          localStorage.removeItem('userInfo')
          localStorage.removeItem('code')
          localStorage.removeItem('generalize_code')
          // localStorage.clear()
          sessionStorage.clear()
          this.$dialog.toast({
            mes: '退出登录成功',
            icon: 'success',
          });
          this.clear();
          localStorage.removeItem('has_input_password');
          this.$$router.go(0)
        }
      } catch (e) {
        console.warn(e);
      }
    },

    judgeLogin(value) {
      this.seaType = value
      if (!this.userInfo.business_id) {
        this.loginDialog = true
        this.entrysStatus = value
        return
      } else {
        switch (value) {
          case 1:
            this.getActivityStatus(1)
            // this.$router.push({
            //   path: '/lucky_wheel',
            //   query: {
            //     type: 1
            //   }
            // })
            break;
          case 3:
            this.getActivityStatus(3)
            // this.show_layer("cancel", "活动未开始");
            break;
          case 2:
            this.getActivityStatus(2)
            // this.$router.push({
            //   path: '/lucky_wheel',
            //   query: {
            //     type: 2
            //   }
            // })
        }
      }
    },

    //  获取活动列表
    async getActivityDetail() {
      try {
        let params = {
          type: 3
        }
        const res = await getActivityDetail(params)             
        if (res.code === 0) {
          this.$router.push({
            path: '/ceremony',
            query: {
              type: 3
            }
          })
        }
        if (res.code === -1 && res.msg === '此用户没有参加该活动的权限') {
          this.show_layer("cancel", "本期活动未达标，下期努力呀~");
        }
        if (res.code === -1 && res.msg !== '此用户没有参加该活动的权限') {
          this.$router.push({
            path: '/ceremony',
            query: {
              type: 3
            }
          })
        }
      } catch (e) {
        // this.$dialog.toast({
        //   mes: '网络繁忙，稍后再试',
        //   icon: 'error',
        //   timeout: 1500
        // });
      }
    },

    monthly() {
      this.$router.push('/lucky_wheel')
    },

    // 弹窗消失
    appear() {
      this.loginDialog = false
    },

    // 点击登录按钮
    submit: debounce( async function (value) {
      let password = value[1].replace(/\s+/g, "")
      let account = value[0]
      let type = this.seaType
      if (!account) {
        this.show_layer("cancel", "请输入客户号");
        this.is_request = false
        return;
      }

      if (!password) {
        this.show_layer("cancel", "请输入密码");
        this.is_request = false
        return;
      }
      try {
        let res = null;
        res = await login4(account, md5(password), type)
        console.log({res})
        this.is_request = false;
        if (res.code === 0) {
          this.loginTxt = '退出登录'
          const {PHPSESSID, user} = res.data;
          const show = res.data.is_show
          if (show) {
            sessionStorage.setItem('show', show)
          } else {
            sessionStorage.setItem('show', '')
          }
          SID = PHPSESSID;
          // this.getSessionId(PHPSESSID);
          this.show_layer("success", "登录成功");
          this.$store.dispatch("getUserInfo");
          // await this.getUser(user);
          await this.getThemeColor();
          try {
            const data = await this.$store.dispatch('getBusinessRate');
            if (!data.code) {
              this.loginDialog = false
              this.judgeLogin(this.entrysStatus)
              // this.$router.push(sessionStorage.route || 'home');
            }
          } catch (e) {
            
          }
        } else  {
          this.show_layer("cancel", res.msg);
        }
      } catch (error) {
        console.warn("Something bad happened in api login: ", error);
      }
    }, 300),

    cancel() {
      this.loginDialog = false
    },

    // 获取主题色
    async getThemeColor() {
      try {
        const res = await getColor(this.$store.state.userInfo.business_id);
        this.img_request = true
        if (res.code === 0) {
          const {color, login_img,login_text} = res.data;
		  
          console.log(login_img);
          this.login_img = login_img;
          this.login_text = login_text;
		  this.txtColor = color;
          document.body.style.setProperty("--main-color", color);
          this.$forceUpdate();
        }
      } catch (error) {
        console.warn("Something bad happened in api getColor: ", error);
      }
    },
  }
}
</script>

<style  lang="less" scoped>
@import "../../../../common/less/variable.less";
@import "../../../../common/less/mixin.less";

  .sea-index {
    width: 100vw;
    min-height: 100vh;
    padding-bottom: 0.5rem;
    overflow: hidden;
    position: relative;
    .sc(0.28rem, #333);

    .login-status {
      position: absolute;
      top: 0.2rem;
      right: 0.2rem;
    }

    .content {
      padding-top: 4rem;

      .entry {
        margin: 0 0.2rem 0.2rem;

        img {
          width: 100%;
          // height: 2.6rem;
        }
      }
    }

    .login-entrys {
      width: 100vw;
      height: 100%;
      background: rgba(0,0,0, 0.4);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
</style>